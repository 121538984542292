import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import { getResponse } from "../firebase/helpers";
import { getFixedNormalizedValue } from "../helpers/helpers";
import { result } from "lodash";
function Resultados() {
  const [data, setData] = useState([]);
  const [personal, setPersonal] = useState({} as any);
  let { id } = useParams();

  const obtenerResultados = async () => {
    const response = await getResponse(id);
    const calculoKeys = ["D", "I", "S", "C"];
    const graphDataArray = calculoKeys.map((key) => {
      return {
        name: key,
        datos: response.calculo[key],
      };
    });
    setData([{ name: "" }, ...graphDataArray, { name: "." }]);
    setPersonal(response);
  };

  useEffect(() => {
    obtenerResultados();
  }, []);

  // Apply normalization based on each page's specific range
  const normalizedData = data.map((item) => {
    return {
      ...item,
      normalizedValue: getFixedNormalizedValue(item.name, item.datos),
    };
  });

  const CustomLabel = (props: any) => {
    const { x, y, stroke, value } = props;

    return (
      <text
        style={{ paddingBottom: "100px" }}
        x={x}
        y={y - 10}
        dy={-4}
        fill={stroke}
        fontSize={16}
        textAnchor="middle"
      >
        {value}
      </text>
    );
  };
  return (
    <div className="flex justify-center items-center py-10 bg-gray-100 min-h-screen">
      <div className="max-w-4xl mx-auto overflow-hidden bg-white shadow-xl rounded-lg">
        <div className="px-10 py-5">
          <h1 className="text-2xl font-bold text-gray-800 mb-2">Resultados de {personal.nombre}</h1>
          <h3 className="text-lg font text-gray-400 mb-6">{personal.email} | {personal.phone}</h3>
          <div className="border-t border-gray-200">
            <div className="flex justify-center items-center py-6">
              <ResponsiveContainer width={500} height={700}>
                <LineChart
                  data={normalizedData}
                  margin={{ top: 20, right: 30, left: 0, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis domain={[0, 100]} />
                  <Tooltip />
                  <ReferenceLine y={50} stroke="#333333" />
                  <Line
                    type="linear"
                    dataKey="normalizedValue"
                    strokeWidth={3}
                    stroke="#8884d8"
                    label={<CustomLabel />}
                  />
                  {/* <ReferenceArea opacity={0} x1={''} x2={'C'} y1={50} y2={100} label={<CustomLabel value="AGRESIVIDAD EMPUJE" />} />
                <ReferenceArea opacity={0} x1={'C'} x2={'D'} y1={50} y2={100} label={<CustomLabel value="CONVINCENTE PRESUASIVO" />} />
                <ReferenceArea opacity={0} x1={''} x2={'C'} y1={0} y2={50} label={<CustomLabel value="CAUTO MODERADO" />} />
                <ReferenceArea opacity={0} x1={'C'} x2={'D'} y1={0} y2={50} label={<CustomLabel value="LOGICO INSISIVO" />} />
                <ReferenceArea opacity={0} x1={'D'} x2={'I'} y1={50} y2={100} label={<CustomLabel value="CONSISTENTE TRANQUILO" />} />
                <ReferenceArea opacity={0} x1={'D'} x2={'I'} y1={0} y2={50} label={<CustomLabel value="IMPULSIVO PACIENTE" />} />
                <ReferenceArea opacity={0} x1={'I'} x2={'S'} y1={50} y2={100} label={<CustomLabel value="SISTEMATICO EXACTO" />} />
                <ReferenceArea opacity={0} x1={'I'} x2={'S'} y1={0} y2={50} label={<CustomLabel value="INDEPENDIENTE" />} /> */}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resultados;
