import React from 'react';
import { currentHost } from '../helpers/helpers';

interface DataItem {
  nombre: string;
  email: string;
  id: string;
  calculo?: Object;
  response?: Object;
  fecha: string;
}

interface DataTableProps {
  data: DataItem[];
}

const DataTable: React.FC<DataTableProps> = ({ data }) => {
  const getStatus = (item: DataItem) => {
    if (item.calculo) {
      return 'Completado';
    }

    if (item.response) {
      return 'En progreso';
    }

    return 'Sin iniciar';
  }

  const getLinkComponentWithUrl = (item: DataItem) => {
    let url = '';
    if (item.calculo) {
      url = `${currentHost()}/cuestionario/${item.id}/resultados`;
    } else {
      url = `${currentHost()}/cuestionario/${item.id}/preguntas`;
    }

    return (
      <a
        href={url}
        target="_blank"
        className="text-primary underline"
      >
        {url}
      </a>
    );
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };

    if (date.getFullYear() !== now.getFullYear()) {
      options.year = 'numeric';
    }

    return date.toLocaleString('es-ES', options);
  }

  return (
    <div className="overflow-hidden border border-gray-200">
      <table className="min-w-full bg-white">
        <thead className="w-full bg-primary text-white sticky top-0">
          <tr>
            <th className="py-2 px-4">Nombre</th>
            <th className="py-2 px-4">Email</th>
            <th className="py-2 px-4">Dirección</th>
            <th className="py-2 px-4">Estatus</th>
          </tr>
        </thead>
      </table>
      <div className="h-[calc(100vh-10rem)]  overflow-y-scroll">
        <table className="min-w-full bg-white">
          <tbody>
          {data.map((item, index) => (
            <tr key={index} className="text-center even:bg-gray-50 hover:bg-gray-100">
              <td className="py-2 px-4 border-b text-left">{formatDate(item.fecha)}</td>
              <td className="py-2 px-4 border-b text-left">{item.nombre}</td>
              <td className="py-2 px-4 border-b text-left">{item.email}</td>
              <td className="py-2 px-4 border-b text-left">{getLinkComponentWithUrl(item)}</td>
              <td className="py-2 px-4 border-b text-left">{getStatus(item)}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTable;
