import React, { useEffect } from 'react';
import DataTable from '../components/DataTable';
import { getResponses } from '../firebase/helpers';

function ResultadosLista() {
  const [data, setData] = React.useState([]);

  const getResponsesFromFirebase = async () => {
    const responsesObject = await getResponses();
    const responsesArray = Object.values(responsesObject);
    setData(responsesArray.sort((a: any, b: any) => {
      // sort by date newest to oldest
      return new Date(b.fecha).getTime() - new Date(a.fecha).getTime();
    }));
  }

  useEffect(() => {
    getResponsesFromFirebase();
  }, []);

  return (
    <div className="p-6">
    <h1 className="text-2xl font-display text-primary mb-4">Respuestas</h1>
    <DataTable data={data} />
  </div>
  );
}

export default ResultadosLista;