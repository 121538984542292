import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import WelcomeScreen from "./pages/WelcomeScreen";
import QuestionsScreen from "./pages/QuestionsScreen";
import CompletedScreen from "./pages/CompletedScreen";
import Resultados from "./pages/Resultados";
import ResultadosLista from "./pages/ResultadosLista";
const auth = getAuth();
function App() {
  const signInAnonymouslyPromise = signInWithEmailAndPassword(
    auth,
    "even.sosa@gmail.com",
    "123456",
  );

  useEffect(() => {
    signInAnonymouslyPromise.catch((error) => {
      console.error(error);
    });
  }, [signInAnonymouslyPromise]);

  return (
    <Router>
      <Routes>
        <Route path="/cuestionario/crear" element={<WelcomeScreen />} />
        <Route
          path="/cuestionario/:id/preguntas"
          element={<QuestionsScreen />}
        />
        <Route
          path="/cuestionario/:id/completado"
          element={<CompletedScreen />}
        />
        <Route path="/cuestionario/:id/resultados" element={<Resultados />} />
        <Route path="/cuestionario/todo/resultados" element={<ResultadosLista />} />
      </Routes>
    </Router>
  );
}

export default App;
