import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OpcionType, PreguntaType } from "../types/pregunta";
import Pregunta from "../components/Pregunta";
import cuestionarioJSON from "../data.json";
import {
  convertResumentToAnswers,
  enviarEmailTerminado,
  resumirDatos,
  sumCodes,
} from "../helpers/helpers";
import { getResponse, updateResponse } from "../firebase/helpers";

function QuestionsScreen() {
  const navigate = useNavigate();
  const cuestionario: PreguntaType[] = cuestionarioJSON;
  // aqui controlo en que página voy
  const [page, setPage] = useState(-1);
  // aquí guardo todo el cuestionario
  const [answers, setAnswers] = useState<PreguntaType[]>(cuestionario);
  // aqui controlo la pregunta actual
  const [data, setData] = useState<PreguntaType>(cuestionario[0]);
  const [nombre, setNombre] = useState("");

  let { id } = useParams();

  const getResponseFirebase = async () => {
    const responses = await getResponse(id);
    const answersPrep = convertResumentToAnswers(
      responses.response,
      cuestionario,
    );

    if (responses?.calculo) {
      navigate(`/cuestionario/${id}/completado`, {
        state: { calculo: responses.calculo },
      });
    }

    setAnswers(answersPrep);
    setData(answersPrep[0]);
    setNombre(responses.nombre);
  };

  useEffect(() => {
    getResponseFirebase();
  }, []);

  const renderInstructions = () => {
    return (
      <div>
        <h1 className="flex items-center justify-center bg-primary text-white font-bold">
          {id} - {nombre}
        </h1>
        <div className="flex flex-col items-center justify-start h-screen bg-gray-100">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-xl w-full">
            <div className="flex items-center mb-4">
              <h2 className="text-xl font-display text-primary flex-1">
                Instrucciones
              </h2>
            </div>
            <div className="grid grid-cols-1 gap-4 items-center text-justify">
              <p className="">
                Las frases descriptivas siguientes, se encuentran agrupadas en
                series de cuatro. Examine cada serie. Seleccione en la columna
                MAS (+). próxima a la frase que en cada grupo mejor lo describa.
                Seleccione bajo la columna MENOS (-) junto a la frase que en
                cada grupo lo describa menos. Asegúrese de marcar SOLAMENTE UNA
                en la columna MAS y SOLAMENTE UNA en la columna MENOS.
              </p>
            </div>
            <div className="flex justify-end">
              <button
                className="mt-10 bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed"
                type="submit"
                onClick={() => {
                  setPage(0);
                  setData(answers[0]);
                }}
              >
                Siguiente
              </button>
            </div>
          </div>

          <div className="flex items-center justify-center mt-4"></div>
        </div>
      </div>
    );
  };

  if (page < 0) {
    return renderInstructions();
  }

  return (
    <div>
      <h1 className="flex items-center justify-center bg-primary text-white font-bold">
        {nombre}
      </h1>
      <div className="flex flex-col items-center justify-start h-screen bg-gray-100">
        <Pregunta
          titulo={data.titulo ?? ""}
          opciones={data.opciones ?? {}}
          onRespuestas={(opciones: OpcionType) => {
            setData({ ...data, opciones });
          }}
          onBack={() => {
            setPage(page - 1);
            setData(answers[page - 1]);
          }}
          onSiguiente={() => {
            const latestData = answers.map((item, index) => {
              if (index === page) {
                return data;
              }
              return item;
            });
            setAnswers(latestData);
            const resumen = resumirDatos(latestData);
            if (page < answers.length - 1) {
              setPage(page + 1);
              setData(answers[page + 1]);

              updateResponse(id, resumen);
            } else {
              const calculo = sumCodes(latestData);
              updateResponse(id, resumen, calculo);
              // navigate to the results page
              enviarEmailTerminado(nombre, id);
              navigate(`/cuestionario/${id}/completado`, {
                state: { calculo },
              });
            }
          }}
        />
      </div>
    </div>
  );
}

export default QuestionsScreen;
