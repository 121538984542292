function CompletedScreen() {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl w-full">
        <h2 className="text-2xl font-display text-primary mb-4">
          Cuestionario completado
        </h2>
        <p className="text-secondary mb-8">
          El cuestionario ha sido completado. Puedes cerrar esta ventana.
        </p>
      </div>
    </div>
  );
}

export default CompletedScreen;
