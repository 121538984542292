import { ref, set, get } from "firebase/database";
import database from "./firebase-config";

export function createEmptyResponse(
  clave: string,
  nombre: string,
  email: string,
  phone: string,
) {
  return set(ref(database, "responses/" + clave), {
    nombre: nombre,
    email: email,
    phone: phone,
    id: clave,
    fecha: new Date().toISOString(),
  });
}

export function updateResponse(clave: string, response: any, calculo?: any) {
  return get(ref(database, "responses/" + clave))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const currentData = snapshot.val();
        set(ref(database, "responses/" + clave), {
          ...currentData,
          response: response,
          calculo: calculo ? calculo : null,
        });
      } else {
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export function getResponse(clave: string) {
  return get(ref(database, "responses/" + clave))
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

export function getResponses() {
  return get(ref(database, "responses"))
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}