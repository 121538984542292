import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBQRoMgyJe-JHV-oqxdiejFmv61OXAojTk",
  authDomain: "cleaver-ba534.firebaseapp.com",
  databaseURL: "https://cleaver-ba534-default-rtdb.firebaseio.com/",
  projectId: "cleaver-ba534",
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Get a reference to the database service using the new modular function
const database = getDatabase(app);

export default database;
