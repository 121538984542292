import { useEffect, useState } from "react";
import { createEmptyResponse } from "../firebase/helpers";
import { currentHost, enviarEmailCuestionario } from "../helpers/helpers";

export default function WelcomeScreen() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [clave, setClave] = useState("");
  const [sent, setSent] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const [error, setError] = useState("");
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [timer, setTimer] = useState(5);

  // A simple regex for basic email validation
  const emailRegex = /\S+@\S+\.\S+/;

  const validateEmail = (email: string) => {
    if (emailRegex.test(email)) {
      setError("");
      return true;
    } else {
      setError("Porfavor, proporcione un email válido.");
      return false;
    }
  };

  const phoneRegex = /^\d{10}$/;

  const validatePhone = (phone: string) => {
    if (phoneRegex.test(phone)) {
      setErrorPhone("");
      return true;
    } else {
      setErrorPhone("Porfavor, ingrese un numero valido a 10 digitos.");
      return false;
    }
  };

  function generateRandomCode() {
    // Function to generate a random segment of the format
    const generateSegment = () =>
      Math.random().toString(36).substring(2, 6).toUpperCase();

    // Generate two segments and concatenate them with a dash
    return `${generateSegment()}-${generateSegment()}`;
  }

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(`${currentHost()}/cuestionario/${clave}/preguntas`)
      .then(
        () => {
          setCopySuccess("Copiado!");
          setTimeout(() => setCopySuccess(""), 2000); // Reset message after 2 seconds
        },
        () => {
          setCopySuccess("Failed to copy");
        },
      );
  };

  useEffect(() => {
    setClave(generateRandomCode());
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (validateEmail(email)) {
      try {
        await createEmptyResponse(clave, name, email, phone);
        await enviarEmailCuestionario(clave, email);
        setSent(true);
        let currentTimer = timer;
        const myInterval = setInterval(() => {
          currentTimer--;
          setTimer(currentTimer);
          if (currentTimer <= 0) {
            clearInterval(myInterval);
            window.location.href = `/cuestionario/${clave}/preguntas`;
          }
        }, 1000)
      } catch (error) {
        console.error(error);
        alert("Error al enviar el cuestionario");
      }
    }
  };

  const renderCompleted = () => (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl w-full">
        <h2 className="text-2xl font-display text-primary mb-4">
          Cuestionario creado
        </h2>
        <p className="text-secondary mb-8">
          El cuestionario ha sido enviado a <b>{email}</b>.
          <br />
          <br />
          <div className="relative">
            <pre className="block p-4 w-full text-sm text-gray-800 bg-gray-100 rounded-lg border border-gray-200 overflow-x-auto">
              <code className="block">
                {`${currentHost()}/cuestionario/${clave}/preguntas`}
              </code>
            </pre>
            <button
              className="absolute top-2 right-2 p-2 text-xs text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300"
              onClick={copyToClipboard}
            >
              Copiar
            </button>
            <span className="absolute top-18 right-2 text-xs text-green-500">
              {copySuccess}
            </span>
            <div className="flex justify-center">
              <span className="text-center">Redireccionando en {timer}</span>
            </div>
          </div>
        </p>
      </div>
    </div>
  );

  if (sent) {
    return renderCompleted();
  }

  return (
    <div className="flex items-start justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-display text-primary mb-4">
          Crear cuestionario
        </h2>
        <p className="text-secondary mb-8">
          Escriba sus datos para comenzar.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="clave"
              className="block text-secondary text-sm font-bold mb-2"
            >
              Código
            </label>
            <input
              type="text"
              id="clave"
              name="clave"
              placeholder="Clave"
              disabled={true}
              className="disabled:opacity-45 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={clave}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-secondary text-sm font-bold mb-2"
            >
              Nombre
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Nombre"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-secondary text-sm font-bold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              placeholder="Email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={() => validateEmail(email)}
            />
            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-secondary text-sm font-bold mb-2"
            >
              Telefono
            </label>
            <input
              type="phone"
              id="telefono"
              name="telefono"
              required
              placeholder="Telefono"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              onKeyUp={() => validatePhone(phone)}
            />
            {errorPhone && (
              <p className="mt-2 text-sm text-red-600">{errorPhone}</p>
            )}
          </div>
          <div className="flex flex-1">
            <button
              className="flex-1 bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed"
              type="submit"
              disabled={!!error || !!errorPhone || !name || !email || !phone}
            >
              Continuar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
