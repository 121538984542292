import axios from "axios";
import { PreguntaType } from "../types/pregunta";

// sum all S, D, I C codes from the json file
// for the ones in "less" and "more" properties
export const sumCodes = (response: PreguntaType[]) => {
  const keys = ["D", "I", "S", "C"];
  const result: {
    [key: string]: number;
  } = {
    S: 0,
    D: 0,
    I: 0,
    C: 0,
  };
  response.forEach((pregunta: PreguntaType) => {
    const opciones = Object.keys(pregunta.opciones);
    for (let i = 0; i < opciones.length; i++) {
      const opcion = pregunta.opciones[opciones[i]];
      if (keys.includes(opcion.less.value)) {
        result[opcion.less.value] += opcion.less.selected;
        if (opcion.less.value === "S" && opcion.less.selected !== 0) {
          console.log("S-less", opciones[i], opcion.less.selected);
        }
      }
      if (keys.includes(opcion.more.value)) {
        result[opcion.more.value] += opcion.more.selected;
        if (opcion.less.value === "S" && opcion.more.selected !== 0) {
          console.log("S-more", opciones[i], opcion.more.selected);
        }
      }
    }
  });
  return result;
};

export const resumirDatos = (response: PreguntaType[]) => {
  const resumen: any = {};
  response.forEach((pregunta: PreguntaType) => {
    const opciones = Object.keys(pregunta.opciones);
    for (let opcion of opciones) {
      const respuesta = pregunta.opciones[opcion];
      if (respuesta.less.selected !== 0 || respuesta.more.selected !== 0) {
        resumen[opcion] = respuesta.less.selected !== 0 ? "less" : "more";
      }
    }
  });

  return resumen;
};

export const convertResumentToAnswers = (
  resumen: any,
  cuestionario: PreguntaType[],
) => {
  const answers: PreguntaType[] = cuestionario.map((pregunta: PreguntaType) => {
    if (!resumen) return pregunta;
    const opciones = Object.keys(pregunta.opciones);
    const newOpciones: any = {};
    for (let opcion of opciones) {
      const respuesta = pregunta.opciones[opcion];
      const less = {
        value: respuesta.less.value,
        selected: resumen[opcion] === "less" ? -1 : 0,
      };
      const more = {
        value: respuesta.more.value,
        selected: resumen[opcion] === "more" ? 1 : 0,
      };
      newOpciones[opcion] = {
        less,
        more,
      };
    }
    return {
      titulo: pregunta.titulo,
      opciones: newOpciones,
    };
  });
  return answers;
};

export const currentHost = () => {
  return `${window.location.protocol}//${window.location.hostname}`;
};

export const enviarEmailTerminado = async (nombre: string, id: string) => {
  const lambdaURL = `https://ys2pcjflgxonr6qiztg43sfwwe0wexvj.lambda-url.us-west-2.on.aws/ `;
  const url = `${currentHost()}/cuestionario/${id}/resultados`;
  axios
    .post(lambdaURL, {
      nombre,
      url,
      titulo: "Cuestionario completado",
      texto: "ha completado su cuestionario. Revisa los resultados",
      to: "rogeliomga@gmail.com",
    })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const enviarEmailCuestionario = async (id: string, email: string) => {
  const lambdaURL = `https://ys2pcjflgxonr6qiztg43sfwwe0wexvj.lambda-url.us-west-2.on.aws/ `;
  const url = `${currentHost()}/cuestionario/${id}/preguntas`;
  axios
    .post(lambdaURL, {
      nombre: "El Arq. Rogelio Gonzalez",
      url,
      titulo: "Cuestionario Cleaver",
      texto: "te ha enviado un cuestionario. Responde",
      to: email,
    })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
};

type CategoryMapping = {
  [key: string]: { [inputValue: string]: number };
};

const testing: CategoryMapping = {
  D: {
    "20": 99,
    "-2": 2,
  },
};

const fixedValueMap: { [category: string]: { [inputValue: string]: number } } =
  {
    D: {
      "20": 99,
      "19": 99,
      "18": 98,
      "17": 98,
      "16": 97,
      "15": 97,
      "14": 96,
      "13": 95,
      "12": 94,
      "11": 92,
      "10": 90,
      "9": 88,
      "8": 84,
      "7": 80,
      "6": 75,
      "5": 70,
      "4": 68,
      "3": 64,
      "2": 60,
      "1": 50,
      "0": 50,
      "-1": 45,
      "-2": 40,
      "-3": 35,
      "-4": 28,
      "-5": 25,
      "-6": 20,
      "-7": 16,
      "-8": 14,
      "-9": 12,
      "-10": 10,
      "-11": 7,
      "-12": 5,
      "-13": 5,
      "-14": 4,
      "-15": 4,
      "-16": 3,
      "-17": 3,
      "-18": 2,
      "-19": 2,
      "-20": 1,
      "-21": 0,
    },
    I: {
      "17": 99,
      "16": 99,
      "15": 99,
      "14": 98,
      "13": 98,
      "12": 98,
      "11": 97,
      "10": 97,
      "9": 97,
      "8": 97,
      "7": 97,
      "6": 95,
      "5": 90,
      "4": 85,
      "3": 80,
      "2": 70,
      "1": 60,
      "0": 50,
      "-1": 45,
      "-2": 32,
      "-3": 25,
      "-4": 20,
      "-5": 16,
      "-6": 10,
      "-7": 7,
      "-8": 5,
      "-9": 3,
      "-10": 2,
      "-11": 2,
      "-12": 2,
      "-13": 2,
      "-14": 1,
      "-15": 1,
      "-16": 1,
      "-17": 1,
      "-18": 1,
      "-19": 0,
    },
    S: {
      "19": 99,
      "18": 99,
      "17": 99,
      "16": 98,
      "15": 98,
      "14": 98,
      "13": 98,
      "12": 97,
      "11": 97,
      "10": 97,
      "9": 96,
      "8": 94,
      "7": 90,
      "6": 84,
      "5": 84,
      "4": 82,
      "3": 80,
      "2": 70,
      "1": 58,
      "0": 50,
      "-1": 46,
      "-2": 42,
      "-3": 32,
      "-4": 28,
      "-5": 25,
      "-6": 18,
      "-7": 14,
      "-8": 8,
      "-9": 7,
      "-10": 4,
      "-11": 3,
      "-12": 2,
      "-13": 2,
      "-14": 2,
      "-15": 1,
      "-16": 1,
      "-17": 1,
      "-18": 0,
      "-19": 0,
    },
    C: {
      "15": 99,
      "14": 99,
      "13": 98,
      "12": 98,
      "11": 98,
      "10": 97,
      "9": 97,
      "8": 97,
      "7": 96,
      "6": 96,
      "5": 96,
      "4": 95,
      "3": 90,
      "2": 84,
      "1": 80,
      "0": 70,
      "-1": 60,
      "-2": 50,
      "-3": 42,
      "-4": 32,
      "-5": 23,
      "-6": 18,
      "-7": 13,
      "-8": 8,
      "-9": 6,
      "-10": 4,
      "-11": 3,
      "-12": 2,
      "-13": 1,
      "-14": 1,
      "-15": 1,
      "-16": 0,
    },
    // Add other categories if necessary, including '' and '.' if they have specific mappings.
  };

// Function to retrieve fixed value based on category and input value
export const getFixedNormalizedValue = (
  category: string,
  inputValue: number,
): number => {
  return fixedValueMap[category]?.[inputValue]; // Returns 0 if no mapping found
};
