import React, { useState } from "react";
import { OpcionType } from "../types/pregunta";

type AnswerState = {
  less: boolean;
  more: boolean;
};

type Answers = {
  [key: number]: AnswerState;
};

type PreguntaProps = {
  titulo: string;
  opciones: OpcionType;
  onRespuestas: (respuestas: OpcionType) => void; // Callback type
  onSiguiente: () => void; // Callback type
  onBack: () => void; // Callback type
};

const Pregunta: React.FC<PreguntaProps> = ({
  titulo,
  opciones,
  onRespuestas,
  onSiguiente,
  onBack,
}) => {
  const optionList = Object.keys(opciones ?? {});

  const handleCheckboxChange = (
    opcion: string,
    type: "less" | "more",
  ): void => {
    // check if answers has already a less or a more value and remove if it does
    const newAnswers = { ...opciones };

    for (const key of optionList) {
      if (key === opcion) {
        newAnswers[key][type].selected = type === "less" ? -1 : 1; // Set selected to 1 for the chosen attribute
        newAnswers[key][type === "less" ? "more" : "less"].selected = 0; // Ensure the other attribute is set to 0
      } else {
        newAnswers[key][type].selected = 0;
      }
    }

    onRespuestas(newAnswers);
  };

  // method isQuestionAnswered should return true if both less and more have at least 1 checked option
  const isQuestionAnswered = (): boolean => {
    const answers = Object.values(opciones);
    let isLess = false;
    let isMore = false;
    for (const answer of answers) {
      if (answer.less.selected !== 0) {
        isLess = true;
      }
      if (answer.more.selected !== 0) {
        isMore = true;
      }
    }

    return isLess && isMore;
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
      <div className="flex items-center mb-4">
        <h2 className="text-xl font-display text-primary flex-1">{titulo}</h2>
        <div className="flex justify-end flex-1 mr-2">
          <div className="text-xl text-secondary pr-4">+</div>
          <div className="text-xl text-center text-secondary">-</div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 items-center">
        {optionList.map((opcion, index) => (
          <div key={index} className="flex items-center justify-between">
            <span className="text-secondary mr-4 w-3/4">{opcion}</span>
            <div className="flex items-center justify-end w-1/4">
              <input
                type="checkbox"
                checked={opciones[opcion].more.selected === 1}
                onChange={() => handleCheckboxChange(opcion, "more")}
                className="form-checkbox h-5 w-5 text-gray-600 mr-4"
              />
              <input
                type="checkbox"
                checked={opciones[opcion].less.selected === -1}
                onChange={() => handleCheckboxChange(opcion, "less")}
                className="form-checkbox h-5 w-5 text-gray-600"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-between mt-10">
        <button
          className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed"
          type="submit"
          onClick={onBack}
        >
          Regresar
        </button>
        <button
          disabled={!isQuestionAnswered()}
          className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed"
          type="submit"
          onClick={onSiguiente}
        >
          Siguiente
        </button>
      </div>
      <div className="pt-10">
        <p className="text-[10px] text-justify">
          Las frases descriptivas siguientes, se encuentran agrupadas en series
          de cuatro. Examine cada serie. Seleccione en la columna MAS (+).
          próxima a la frase que en cada grupo mejor lo describa. Seleccione
          bajo la columna MENOS (-) junto a la frase que en cada grupo lo
          describa menos. Asegúrese de marcar SOLAMENTE UNA en la columna MAS y
          SOLAMENTE UNA en la columna MENOS.
        </p>
      </div>
    </div>
  );
};

export default Pregunta;
